<template>
  <b-container class="container-content">
    <b-form>
      <b-row>
        <b-col xl="4">
          <b-card class="card-name mb-xl-4">
            <div class="d-flex">
              <div class="card-name-img">
                <b-img :src="user.image_url" @error="(e) => (e.target.src = require('../assets/images/preview-upload_2.png'))"  alt="profile"></b-img>
              </div>
              <div>
                <h5>{{ `${user.first_name} ${user.last_name}` }}</h5>
                <b-button variant="primary" @click="onClickChangePhoto">{{
                  $t("button.change_photo")
                }}</b-button>
              </div>
            </div>
          </b-card>
          <b-card
            :header="t('notifications')"
            header-text-variant="white"
            header-bg-variant="primary"
            class="card-my-account"
            v-if="false"
          >
            <b-list-group class="list-group-flush">
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                {{ t("send_email_before_24hr") }}
                <b-form-checkbox
                  switch
                  v-model="form.send_email_before_booking"
                ></b-form-checkbox>
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                {{ t("send_email_remaining_credits") }}
                <b-form-checkbox
                  switch
                  v-model="form.send_email_when_remaining_credits"
                ></b-form-checkbox>
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <b-card
            :header="t('privacy_setting')"
            header-text-variant="white"
            header-bg-variant="primary"
            class="card-my-account"
            v-if="false"
          >
            <b-list-group class="list-group-flush">
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                {{ t("i_dont_mind_letting_fb") }}
                <b-form-checkbox
                  switch
                  v-model="form.dont_mind_let_fb_friends"
                ></b-form-checkbox>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>

        <b-col xl="8">
          <b-card
            :header="t('personal_information')"
            header-text-variant="white"
            header-bg-variant="primary"
            class="card-my-account"
          >
            <b-row>
              <b-col xl="6">
                <b-form-group :label="$t('form_label.first_name')">
                  <b-form-input v-model="form.first_name"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col xl="6">
                <b-form-group :label="$t('form_label.last_name')">
                  <b-form-input v-model="form.last_name"></b-form-input>
                </b-form-group>
              </b-col>
              <!--   <b-col xl="6">
               <b-form-group :label="$t('form_label.facebook_email')">
                  <b-form-input :value="user.fb_email" disabled></b-form-input>
                </b-form-group
              </b-col>> -->
              <b-col xl="6">
                <b-form-group
                  :label="$t('form_label.facebook_email')"
                  v-if="user.provider === 'EMAIL'"
                >
                  <b-input-group>
                    <b-form-input v-model="form.email" disabled></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" @click="onClickChangeEmail">
                        <i class="far fa-edit"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <b-form-group :label="$t('form_label.facebook_email')" v-else>
                  <b-form-input v-model="form.email"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col xl="6" v-if="user.provider === 'EMAIL'">
                <b-form-group :label="$t('form_label.password')">
                  <b-input-group>
                    <b-form-input
                      type="password"
                      v-model="password"
                      disabled
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="onClickChangePassword"
                      >
                        {{ $t("button.change_password") }}
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col xl="6" v-else></b-col>
              <b-col xl="6">
                <b-form-group :label="$t('form_label.phone_number')">
                  <b-input-group>
                    <b-input-group-text
                      slot="prepend"
                      class="bg-primary text-white"
                      >+66</b-input-group-text
                    >
                    <b-form-input v-model="form.phone" disabled></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" @click="openVerifyPhoneModal">
                        <i class="far fa-edit"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            :header="t('location')"
            header-text-variant="white"
            header-bg-variant="primary"
            class="card-my-account"
          >
            <b-row>
              <b-col xl="6">
                <b-form-group :label="$t('form_label.default_location_1')">
                  <AddressSelect
                    :value="defaultLocation[0]"
                    @input="onLocationChanged"
                    key="location1"
                  />
                </b-form-group>
                <b-form-group :label="$t('form_label.default_location_2')">
                  <AddressSelect
                    :value="defaultLocation[1]"
                    @input="onLocationChanged"
                    key="location2"
                  />
                </b-form-group>
                <b-form-group :label="$t('form_label.default_location_3')">
                  <AddressSelect
                    :value="defaultLocation[2]"
                    @input="onLocationChanged"
                    key="location3"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <b-row>
            <b-col xl="6">
              <b-button
                variant="primary"
                block
                @click="doUpdateProfile"
                :disabled="shouldDisableSaveButton"
                >{{ saveButtonText }}</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
    <BaseModal
      id="changeEmail"
      @close="onCloseModal"
      :hideCloseButton="emailSent"
    >
      <div v-if="!ischangeEmail">
        <div class="justify-content-center d-flex">
          <div class="page-header-text top10">
            <h4 class="mb-3 w380">{{ $t("warning.email_address_change") }}</h4>
          </div>
        </div>
        <div class="text-center px-2">
          <p class="my-3">
            {{ $t("warning.enter_your_password_to_verify_yourself") }}
          </p>
        </div>
        <div>
          <b-form @submit="checkCurrentEmail">
            <b-form-group>
              <p class="text-xl">{{ $t("warning.password") }}</p>
              <b-form-input
                type="password"
                :placeholder="$t('warning.password')"
                v-model="currentPassword"
                size="default"
                required
              ></b-form-input>
            </b-form-group>
            <p class="text-danger my-3" v-if="isCurrent === 'INVALID_PASSWORD'">
              <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
              {{ $t("warning.your_password_is_incorrect") }}
            </p>
            <p
              class="text-danger my-3"
              v-if="isCurrent === 'TOO_MANY_ATTEMPTS_TRY_LATER'"
            >
              <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
              {{ $t("warning.try_many") }}
            </p>
            <b-row class="justify-content-center">
              <b-button
                type="submit"
                variant="primary"
                class="btn-primary my-1 w380"
                :disabled="disable"
                >{{ $t("button.confirm") }}</b-button
              >
              <br />
              <b-button
                @click="goBack_3"
                variant="outline-light"
                class="btn-comin my-1 w380"
                >{{ $t("button.go_back") }}</b-button
              >
            </b-row>
          </b-form>
        </div>
      </div>
      <div v-else>
        <div v-if="!emailSent">
          <div class="justify-content-center d-flex">
            <div class="page-header-text top10">
              <h4 class="mb-3 w380">
                {{ $t("warning.email_address_change") }}
              </h4>
            </div>
          </div>
          <div class="text-center px-2">
            <p class="my-3">
              {{ $t("warning.please _fill_in_your_new_email_adress") }}
            </p>
          </div>
          <div>
            <b-form @submit="confirmChangeEmail">
              <b-form-group>
                <p class="text-xl">{{ $t("warning.new_email") }}</p>
                <b-form-input
                  type="email"
                  v-model="newEmail"
                  size="default"
                  required
                ></b-form-input>
              </b-form-group>
              <p
                class="text-danger my-3"
                v-if="isCurrent === 'INVALID_PASSWORD'"
              >
                <b-icon
                  icon="exclamation-circle-fill"
                  variant="danger"
                ></b-icon>
                {{ $t("warning.your_password_is_incorrect") }}
              </p>
              <p
                class="text-danger my-3"
                v-if="currentEmail === 'EMAIL_EXISTS'"
              >
                <b-icon
                  icon="exclamation-circle-fill"
                  variant="danger"
                ></b-icon>
                {{ $t("warning.email_has_already_been_use") }}
              </p>
              <p
                class="text-danger my-3"
                v-if="currentEmail === 'INVALID_EMAIL'"
              >
                <b-icon
                  icon="exclamation-circle-fill"
                  variant="danger"
                ></b-icon>
                {{ $t("warning.your_email_is_incorrect") }}
              </p>

              <b-row class="justify-content-center">
                <b-button
                  type="submit"
                  @change="resetEmailStatus"
                  variant="primary"
                  class="btn-primary my-1 w380"
                  :disabled="newdisable"
                  >{{ $t("button.confirm") }}</b-button
                >
                <br />
                <b-button
                  @click="goBack_4"
                  variant="outline-light"
                  class="btn-comin my-1 w380"
                  >{{ $t("button.go_back") }}</b-button
                >
              </b-row>
            </b-form>
          </div>
        </div>
        <div v-else>
          <div class="justify-content-center d-flex">
            <div class="page-header-text top10">
              <h4 class="mb-3 w380">
                {{ $t("warning.email_address_change") }}
              </h4>
            </div>
          </div>
          <div class="text-center px-2" v-if="$i18n.locale === 'th'">
            <p class="my-3">
              {{ $t("warning.sent_email") }}
            </p>
          </div>
          <div class="text-center px-2" v-else>
            <p class="my-3">
              {{ $t("warning.sent_email") }}
            </p>
            <p class="my-3">
              {{ $t("warning.sent_email_2") }}
            </p>
          </div>
        </div>
      </div>

      <!-- {{ modalText }} -->
    </BaseModal>
    <BaseModal id="changePassword" @close="onCloseModal">
      <div v-if="!ischangePassword">
        <div class="justify-content-center d-flex">
          <div class="page-header-text top10">
            <h4 class="mb-3 w380">{{ $t("warning.change_password") }}</h4>
          </div>
        </div>
        <!-- <div class="text-center px-2">
          <p class="my-3">
            {{ $t("warning.please_enter_your_current_password") }}
          </p>
        </div> -->
        <div>
          <b-form @submit="checkCurrentPassword">
            <b-form-group>
              <p class="text-xl">{{ $t("warning.current_password") }}</p>
              <b-form-input
                type="password"
                :placeholder="$t('warning.current_password')"
                v-model="currentPassword"
                size="default"
                required
              ></b-form-input>
            </b-form-group>
            <p class="text-danger my-3" v-if="isCurrent === 'INVALID_PASSWORD'">
              <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
              {{ $t("warning.your_password_is_incorrect") }}
            </p>
            <p
              class="text-danger my-3"
              v-if="isCurrent === 'TOO_MANY_ATTEMPTS_TRY_LATER'"
            >
              <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
              {{ $t("warning.try_many") }}
            </p>
            <b-row class="justify-content-center">
              <b-button
                type="submit"
                variant="primary"
                class="btn-primary my-1 w380"
                :disabled="disable"
                >{{ $t("button.confirm") }}</b-button
              >
              <br />
              <b-button
                @click="goBack"
                variant="outline-light"
                class="btn-comin my-1 w380"
                >{{ $t("button.go_back") }}</b-button
              >
            </b-row>
          </b-form>
        </div>
      </div>
      <div v-else>
        <div class="justify-content-center d-flex">
          <div class="page-header-text top10">
            <h4 class="mb-3 w380">{{ $t("warning.change_password") }}</h4>
          </div>
        </div>
        <!-- <div class="text-center px-2">
          <p class="my-3">
            {{ $t("warning.please_enter_your_new_password") }}
          </p>
          <p>
            {{ $t("warning.confirm_and_press_reset_password") }}
          </p>
        </div> -->
        <div v-if="!successChagePassword">
          <b-form @submit="confirmChangePassword">
            <b-form-group>
              <p class="text-xl">{{ $t("reset_password.new_password") }}</p>
              <b-form-input
                type="password"
                v-model="newPassword"
                @change="(isRetry = true), (generatErr = false)"
                :placeholder="$t('reset_password.new_password')"
                size="default"
                required
              ></b-form-input>
              <p class="text-xl mt-3">
                {{ $t("reset_password.confirm_new_password") }}
              </p>
              <b-form-input
                type="password"
                v-model="confirmPassword"
                @change="(isRetry = true), (generatErr = false)"
                :placeholder="$t('reset_password.confirm_new_password')"
                size="default"
              ></b-form-input>
            </b-form-group>
            <p class="text-danger my-3" v-if="isCurrent === 'INVALID_PASSWORD'">
              <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
              {{ $t("warning.your_password_is_incorrect") }}
            </p>
            <p v-if="generatErr" class="text-danger mt-3">
              <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
              {{
                $t(
                  "pages.reset_password.the_two passwords_entered_must_be_the_same"
                )
              }}
            </p>

            <b-row class="justify-content-center">
              <b-button
                type="submit"
                variant="primary"
                class="btn-primary my-1 w380"
                :disabled="newdisable"
                >{{ $t("button.change_password") }}</b-button
              >
              <br />
              <b-button
                @click="goBack_2"
                variant="outline-light"
                class="btn-comin my-1 w380"
                >{{ $t("button.go_back") }}</b-button
              >
            </b-row>
          </b-form>
        </div>
        <div class="text-center px-2" v-else>
          <p class="my-3">
            {{ $t("reset_password.change_password_successful") }}
          </p>
        </div>
      </div>

      <!-- {{ modalText }} -->
    </BaseModal>
    <BaseModal id="update-profile-modal" @close="onCloseModal">
      {{ modalText }}
    </BaseModal>
    <BaseModal
      id="update-photo-modal"
      @close="clearImageFile"
      :noCloseOnBackdrop="true"
    >
      <AvatarUploader @onCrop="onCropImg" />
      <div>
        <div class="text-center" v-if="imageBase64">
          <b-img :src="imageBase64" class="preview-profile-image"></b-img>
          <div class="text-center">
            <b-button
              variant="primary"
              @click="onUploadImage"
              :disabled="isUpdatingProfile"
              class="mt-4"
              >{{ updateButtonText }}</b-button
            >
          </div>
        </div>
      </div>
      <!-- <hr /> -->
      <div class="text-center">
        <!-- <b-button
          variant="primary"
          @click="doUpdatePhotoFromFB"
          :disabled="isUpdatingProfile"
        >
          {{ updatePhotoFBButtonText }}
        </b-button> -->
      </div>
    </BaseModal>
    <BaseModal id="verify-phone-modal">
      <VerifyPhone @onSuccess="closeVerifyPhoneModal" />
    </BaseModal>
  </b-container>
</template>

<script>
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { createNamespacedHelpers } from "vuex";
import AddressSelect from "../components/AddressSelect";
import BaseModal from "../components/Modal/BaseModal";
import VerifyPhone from "../components/VerifyPhone";
import { toBase64, base64ToFile } from "../utils";
import AvatarUploader from "../components/AvatarUploader";

const { mapState, mapActions } = createNamespacedHelpers("user");
const AuthStore = createNamespacedHelpers("auth");
@Component({
  computed: {
    ...mapState({ user: (state) => state }),
    ...AuthStore.mapState(["isCurrent", "isChangePassword", "currentEmail"]),
  },
  methods: {
    ...mapActions([
      "getUserProfile",
      "updateUserProfile",
      "updateUserFBImage",
      "uploadPhotoFromFile",
    ]),
    ...AuthStore.mapActions([
      "checklogInEmail",
      "changePassword",
      "signOut",
      "changeEmail",
    ]),
    ...AuthStore.mapMutations(["ISCURRENTPASSWORD", "ISCHANGEEMAIL"]),
  },
  components: { AddressSelect, BaseModal, VerifyPhone, AvatarUploader },
})
export default class MyAccount extends Vue {
  imageFile = null;
  imageBase64 = null;
  disable = false;
  form = {
    first_name: "",
    last_name: "",
    phone: "",
    user_locations: [],
    send_email_before_booking: false,
    send_email_when_remaining_credits: false,
    dont_mind_let_fb_friends: false,
    email: "",
  };
  successChagePassword = false;

  emailSent = false;
  newEmail = "";
  ischangeEmail = false;
  generatErr = false;
  ischangePassword = false;
  password = "aaaaaa";
  currentPassword = "";
  newdisable = false;
  defaultLocation = [
    {
      description: "",
      main_text: "",
      place_id: "",
      secondary_text: "",
    },
    {
      description: "",
      main_text: "",
      place_id: "",
      secondary_text: "",
    },
    {
      description: "",
      main_text: "",
      place_id: "",
      secondary_text: "",
    },
  ];
  newPassword = "";
  confirmPassword = "";
  modalText = "";
  isSaving = false;
  isUpdatingProfile = false;
  resultPassword = "";

  get saveButtonText() {
    return this.isSaving ? this.$t("button.saving") : this.$t("button.save");
  }

  get updatePhotoFBButtonText() {
    console.log("sss", this.user);
    return this.isUpdatingProfile ||
      this.user.provider === "LINE" ||
      this.user.provider === "EMAIL"
      ? this.$t("button.updating")
      : this.$t("button.update_photo_from_facebook");
  }

  get updateButtonText() {
    return this.isUpdatingProfile
      ? this.$t("button.updating")
      : this.$t("button.update");
  }

  t(key) {
    return this.$t(`pages.my_account.${key}`);
  }

  get shouldDisableSaveButton() {
    const totalLocations =
      this.user.user_locations.length > 3 ? 3 : this.user.user_locations.length;
    let isUpdateNewLocation = false;
    for (let i = 0; i < totalLocations; i++) {
      const isSameLocation =
        this.user.user_locations[i].main_text ===
        this.defaultLocation[i].main_text;
      if (!isSameLocation) {
        isUpdateNewLocation = true;
      }
    }
    const totalCurrrentDefaultLocation = this.defaultLocation.filter(
      (location) => !!location.main_text
    ).length;
    if (!isUpdateNewLocation) {
      isUpdateNewLocation = totalCurrrentDefaultLocation != totalLocations;
    }
    return (
      this.form.first_name === this.user.first_name &&
      this.form.last_name === this.user.last_name &&
      this.form.email === this.user.email &&
      this.form.send_email_before_booking ===
        this.user.send_email_before_booking &&
      this.form.phone === this.user.phone &&
      this.form.send_email_when_remaining_credits ===
        this.user.send_email_when_remaining_credits &&
      this.form.dont_mind_let_fb_friends ===
        this.user.dont_mind_let_fb_friends &&
      !isUpdateNewLocation
    );
  }
  confirmChangeEmail(e) {
    e.preventDefault();

    // console.log("this.newPassword", this.newPassword);
    this.changeEmail({
      email: this.newEmail,
      lang: this.$i18n.locale,
    });
  }
  confirmChangePassword(e) {
    e.preventDefault();
    if (this.newPassword !== this.confirmPassword) {
      this.generatErr = true;
    } else {
      console.log("this.newPassword", this.newPassword);
      this.changePassword(this.newPassword);
    }
  }

  async mounted() {
    console.log("mounted", this.isCurrent);
    this.ISCURRENTPASSWORD(null);
    await this.getUserProfile();
    Object.assign(this.form, this.user); // assign user data into form data
    this.setUserLocations();
  }
  async doUpdateProfile() {
    const locations = this.defaultLocation
      .filter((location) => location.main_text.length > 0)
      .map((location, i) => ({
        ...location,
        location_name: location.main_text,
        number: i + 1,
      }));
    const payload = {
      first_name: this.form.first_name,
      last_name: this.form.last_name,
      phone: this.form.phone,
      email: this.form.email,
      send_email_before_booking: this.form.send_email_before_booking,
      send_email_when_remaining_credits: this.form
        .send_email_when_remaining_credits,
      dont_mind_let_fb_friends: this.form.dont_mind_let_fb_friends,
      user_locations: [...locations],
    };
    this.isSaving = true;
    const result = await this.updateUserProfile(payload);
    if (true) {
      this.modalText = this.$t("form_label.profile_detail_updated");
      this.$root.$emit("bv::show::modal", "update-profile-modal");
    }
    this.isSaving = false;
  }
  async checkCurrentPassword(e) {
    e.preventDefault();
    await this.checklogInEmail({
      email: this.user.email,
      password: this.currentPassword,
    });
  }
  async checkCurrentEmail(e) {
    e.preventDefault();
    await this.checklogInEmail({
      email: this.user.email,
      password: this.currentPassword,
      isEmail: true,
    });
  }

  onCloseModal() {}
  @Watch("user.user_locations")
  setUserLocations() {
    const locations = this.user.user_locations.map((location, index) => ({
      number: index + 1,
      description: location.description,
      main_text: location.main_text,
      latitude: location.latitude,
      longitude: location.longitude,
      secondary_text: location.secondary_text,
      place_id: location.place_id,
    }));
    this.defaultLocation = [...locations].slice(0, 3);
    if (this.defaultLocation.length < 3) {
      const totalLocations = this.defaultLocation.length;
      for (let i = 0; i < 3 - totalLocations; i++) {
        this.defaultLocation.push({
          description: "",
          main_text: "",
          place_id: "",
          secondary_text: "",
          latitude: "",
          longitude: "",
          number: this.defaultLocation.length + 1,
        });
      }
    }
  }
  onClickChangePassword() {
    this.ISCURRENTPASSWORD(null);
    this.$root.$emit("bv::show::modal", "changePassword");

    this.currentPassword = "";
    this.ischangePassword = false;
    this.successChagePassword = false;
  }
  resetEmailStatus() {
    this.ISCHANGEEMAIL = null;
    this.newdisable = false;
  }
  onClickChangeEmail() {
    this.ISCURRENTPASSWORD(null);
    this.ISCHANGEEMAIL(null);
    this.emailSent = false;
    this.ischangeEmail = false;
    this.currentPassword = "";
    this.newEmail = "";
    this.$root.$emit("bv::show::modal", "changeEmail");
  }
  onClickChangePhoto() {
    this.$root.$emit("bv::show::modal", "update-photo-modal");
  }
  goBack() {
    this.$root.$emit("bv::hide::modal", "changePassword");
  }
  goBack_3() {
    this.$root.$emit("bv::hide::modal", "changeEmail");
  }
  goBack_2() {
    this.ischangePassword = false;
  }
  goBack_4() {
    this.ischangeEmail = false;
  }
  async doUpdatePhotoFromFB() {
    this.isUpdatingProfile = true;
    await this.updateUserFBImage();
    this.isUpdatingProfile = false;
    this.$root.$emit("bv::hide::modal", "update-photo-modal");
  }

  @Watch("imageFile")
  async onImageFileChanged() {
    if (!this.imageFile) return;
    const imgBase64 = await toBase64(this.imageFile);
    this.imageBase64 = imgBase64;
  }

  onCropImg(imgBase64) {
    this.imageBase64 = imgBase64;
  }

  onLocationChanged(newLocation) {
    const newDefaultLocation = [];
    this.defaultLocation.forEach((loc) => {
      if (loc.number === newLocation.number) {
        newDefaultLocation.push({ ...newLocation });
      } else {
        newDefaultLocation.push({ ...loc });
      }
    });

    this.defaultLocation = [...newDefaultLocation];
  }

  async onUploadImage() {
    let formData = new FormData();
    const file = await base64ToFile(this.imageBase64, `avatar.png`);
    formData.append("image", file, file.name);
    this.isUpdatingProfile = true;
    await this.uploadPhotoFromFile(formData);
    this.isUpdatingProfile = false;
    this.$root.$emit("bv::hide::modal", "update-photo-modal");
    this.clearImageFile();
  }
  clearImageFile() {
    this.imageFile = null;
    this.imageBase64 = null;
  }

  openVerifyPhoneModal() {
    this.$root.$emit("bv::show::modal", "verify-phone-modal");
  }

  closeVerifyPhoneModal(newPhoneNumber) {
    this.form.phone = newPhoneNumber;
    this.$root.$emit("bv::hide::modal", "verify-phone-modal");
  }
  @Watch("isCurrent")
  consoleLog(newVal) {
    console.log("isCurrent", newVal);
    if (newVal.localId) {
      this.ischangePassword = true;
    }
    if (newVal.isEmail) {
      this.ischangeEmail = true;
    }
  }
  @Watch("isChangePassword")
  async logIsChangePass(newVal) {
    console.log("isChangePassword", newVal);
    if (newVal.kind && newVal.localId) {
      this.password = this.newPassword;
      this.currentPassword = null;
      this.newPassword = null;
      this.confirmPassword = null;
      // this.ischangePassword = false;
      this.successChagePassword = true;
      // this.$root.$emit("bv::hide::modal", "changePassword");
      // await this.signOut();
      // this.$router.push({ name: 'Home' });
    }
  }
  @Watch("currentEmail")
  async logchangeEmail(newVal) {
    console.log("current Email", newVal);
    if (newVal.error) {
      // newVal = newVal.error;
      this.ISCHANGEEMAIL(newVal.error);
      console.log("have error", newVal);
    } else if (newVal.email === this.newEmail) {
      this.emailSent = true;
      this.form.email = this.newEmail;
      setTimeout(() => {
        location.reload();
      }, 3000);
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-profile-image {
  width: 200px;
  height: 200px;
}
.w380 {
  width: 100%;
}
.cursor-point {
  cursor: pointer !important;
}
@media (max-width: 767.98px) {
  .w380 {
    width: 300px !important;
    font-size: 1rem !important;
  }
}
@media (max-width: 375px) {
  .w380 {
    width: 250px !important;
    font-size: 0.95rem !important;
  }
}
@media (max-width: 340px) {
  .w380 {
    width: 200px !important;
    font-size: 0.95rem !important;
  }
}
</style>
