<template>
  <div class="py-2 d-flex align-items-center">
    <div class="pin-icon"></div>
    <div>
      <span>{{ address.main_text }} </span>
      <span>{{ address.secondary_text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['address'],
}
</script>

<style scoped>
span {
  font-size: 14px;
}
span:first-child {
  font-weight: bold;
}
.pin-icon {
  min-width: 24px;
  height: 24px;
  background-image: url('../assets/images/icons/map_pin.svg');
  display: flex;
  flex-direction: column;
  margin: 0 0.5em;
  justify-content: center;
}
</style>
