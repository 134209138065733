<template>
  <div>
    <b-form-checkbox
      id="agreement-checkbox"
      v-model="isAgreePolicy"
      :value="true"
      :unchecked-value="false"
      class="my-2"
    >
      <span>{{ $t('form_label.i_understand_that_bluuu') }}</span>
      <span @click="openServiceModal('Terms')" style="cursor: pointer;">{{
        $t('form_label.terms_and_conditions_of_use')
      }}</span>
      <span>{{ $t('form_label.and') }}</span>
      <span @click="openServiceModal()" style="cursor: pointer;">{{
        $t('form_label.scope_of_service')
      }}</span>
    </b-form-checkbox>
    <BaseModal id="agreements-modal" size="lg">
      <ServicesContent />
    </BaseModal>
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator'
import BaseModal from './Modal/BaseModal'
import ServicesContent from './Modal/ServicesContent'
@Component({
  props: {
    value: false
  },
  components: { BaseModal, ServicesContent }
})
export default class AgreementCheckBox extends Vue {
  isAgreePolicy = false

  @Watch('isAgreePolicy')
  onChange(newVal) {
    console.log(newVal)
    this.$emit('input', this.isAgreePolicy)
  }

  openServiceModal(contentType) {
    if (contentType === 'Terms') {
      this.$router.push({name: 'Terms'})
      // window.open(window.location.origin + '/terms')
    } else {
      this.$root.$emit('bv::show::modal', 'agreements-modal')
    }
    this.isAgreePolicy = !this.isAgreePolicy
  }
}
</script>

<style></style>
