<template>
  <div v-show="!isCropped">
    <div v-if="imageBase64">
      <vue-cropper :aspect-ratio="1 / 1" ref="cropper" :src="imageBase64" alt="Source Image">
      </vue-cropper>
      <div class="text-center">
        <b-button variant="primary" @click="cropImage" :disabled="isUpdatingProfile" class="mt-4">{{
          $t('button.crop_image')
        }}</b-button>
      </div>
    </div>
    <b-form-group :label="$t('form_label.choose_image_file')" v-else>
      <b-form-file
        v-model="imageFile"
        :placeholder="$t('form_label.choose_a_file_or_drop_it_here')"
        drop-placeholder="Drop file here..."
        accept="image/*"
        :browse-text="$t('button.browse')"
      ></b-form-file>
    </b-form-group>
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { toBase64 } from '../utils'
@Component({ components: { VueCropper } })
export default class AvatarUploader extends Vue {
  imageFile = null
  imageBase64 = null
  isUpdatingProfile = false
  isCropped = false
  cropImg = ''

  cropImage() {
    this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
    this.$emit('onCrop', this.cropImg)
    this.isCropped = true
  }

  @Watch('imageFile')
  async onImageFileChanged() {
    if (!this.imageFile) return
    const imgBase64 = await toBase64(this.imageFile)
    this.imageBase64 = imgBase64
  }
}
</script>

<style></style>
