import firebase from 'firebase/app'
import 'firebase/auth'

import { getEnvName, getFirebaseConfig } from '../helpers/network'

const host = window.location.host
const env = getEnvName(host)
const config = getFirebaseConfig(env)

firebase.initializeApp(config)

const firebaseAuth = firebase.auth()

export { firebaseAuth }
